import React from 'react'

export default function Strip(){
    return<>
    
<html lang="en">
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
</head>
<body>
    <>
    <div className="u-uppart">
      <p>Bienvenue sur la boutique en ligne Merchsentry</p>
      <div className="select-lang-om">
      <select className="mx-3">
          <option value="eng">Eng</option>
          <option value="fr">Fr</option>
          <option value="ar">Ar</option>
     
        </select>
        <select className="mx-3">
          <option value="usd">USD</option>
          <option value="mad">MAD</option>

        </select>
      </div>
    </div>
    </>
</body>
</html>
    </>
}