import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
// import "bootstrap/dist/css/bootstrap.min.css"
import "./Homevisiteur.css"
import brand1 from "../../Admin/IMG/Logo_Decathlon_RVB 1.png"
import brand2 from "../../Admin/IMG/Group.png"
import brand3 from "../../Admin/IMG/logo 1.png"
import brand4 from "../../Admin/IMG/s1.png"
import panier from "../../Admin/IMG/cart.fill.png"


import { Link } from "react-router-dom"
import imgProduit from "../../Admin/IMG/cassette-8-vitesses-12x32 1.png"
import imgProduit3 from "../../Admin/IMG/4.png"

import imgProduit2 from "../../Admin/IMG/x1-bike_10w50_packshot_1L_front_1000px-768x768 1.png"

import logoBrand from "../../Admin/IMG/Logo_Decathlon_RVB 1.png"
import { useEffect, useState } from "react"
import { ArticleInfo } from "../../../Modeles/ArticleModel"
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService"
import CardContainer from "../../../components/CardContainer"

export interface ProductType {

    product: ArticleInfo[],
    messageErros: string,
}
export default function HomeVisiteur() {
    const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
    const [state, setState] = useState<ProductType>({
        product: [] as ArticleInfo[],
        messageErros: "aucun produit"
    });
    const [customRange, setCustomRange] = useState<{ min: string; max: string }>({ min: "", max: "" });
    const [priceRange, setPriceRange] = useState<[number, number]>([0, 500000]);

    const predefinedRanges: { label: string; value: [number, number] }[] = [
      { label: "Tous les prix", value: [0, 500000] },
      { label: "Moins de 10 000 DH", value: [0, 10000] },
      { label: "10 000 DH à 50 000 DH", value: [10000, 50000] },
      { label: "50 000 DH à 100 000 DH", value: [50000, 100000] },
      { label: "100 000 DH à 200 000 DH", value: [100000, 200000] },
      { label: "200 000 DH à 300 000 DH", value: [200000, 300000] },
      { label: "300 000 DH à 500 000 DH", value: [300000, 500000] },
    ];
    const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
    const [Message, setMessage] = useState("");
    const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 12;
    const [totalPages, setTotalPages] = useState(1);
    const [distances, setDistances] = useState<Map<number, number>>(new Map());
    const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

     // Map to store distances by product ID
     const filterByLocation = (product: ArticleInfo) => {
    if (!userLocation) {
        return { isMatch: true, distance: 0 }; // Default to a match if no location
    }

    const toRadians = (deg: number) => deg * (Math.PI / 180);
    const earthRadius = 6371;

    const lat1 = toRadians(userLocation.latitude);
    const lon1 = toRadians(userLocation.longitude);
    const lat2 = toRadians(33.5793544); 
    const lon2 = toRadians(-7.5714198);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers

    return { isMatch: true, distance }; // Return match status and calculated distance
};
const handlePageChange = (page: number) => {
  setCurrentPage(page);
};

useEffect(() => {
  setLoading(true);
  AfficheProductsService()
    .getProduct()
    .then((res: any) => {
      const searchTerm = search.toLowerCase();
      setAlldata(res.data.alldata);

      const filtered = res.data.alldata
        .filter((pro: ArticleInfo) => {
          const designation = pro.Designation.toLowerCase();
          const designationAr = pro.DesignationAr?.toLowerCase();
          const refArticle = pro.RefArticle.toString().toLowerCase();
          const sub = pro.LibelleSubstitut?.toString().toLowerCase();

          // Match based on search term and article fields
          const isSearchMatch =
            searchTerm === "" ||
            designation.includes(searchTerm) ||
            designationAr?.includes(searchTerm) ||
            refArticle.startsWith(searchTerm) ||
            sub?.startsWith(searchTerm);

          if (!isSearchMatch) return false;

          const price = pro.PrixVenteArticleHT; // Assuming price is stored here
          const isPriceInRange = price >= priceRange[0] && price <= priceRange[1];

          // Check location filter if applicable
          const { isMatch } = filterByLocation(pro);
          return isMatch && isPriceInRange;
        })
        .map((pro: ArticleInfo) => {
          const { distance } = filterByLocation(pro);
          return { ...pro, distance };
        });

      // Sort the filtered products by distance
      filtered.sort((a: { distance: number }, b: { distance: number }) => a.distance - b.distance);

      // Pagination logic
      const paginatedProducts = filtered.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

      setFilteredProducts(paginatedProducts);
    })
    .catch((err: any) => console.error("Error fetching products:", err))
    .finally(() => setLoading(false));
}, [userLocation, search, currentPage, priceRange]);



    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (selectedValue === '2') {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setUserLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        
                        });
                        setMessage("");
                    },
                    (error) => {
                        setMessage('Please enable your location.');
                    }
                );
            } else {
                setMessage('Geolocation is not supported by your browser.');
            }
        } else {
            setUserLocation(null);
            setMessage("");
        }
    };
    const handleChangePage = (pageNumber: number) => {
        const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value =setSearch(e.target.value.trim());
        console.log("Search value:", value);

    };
    

    // const filterByDate = (productDate: string) => {
    //     const today = new Date();
    //     const productDateObj = new Date(productDate);
    
    //     switch (date) {
    //         case "Ce jour":
    //             return productDateObj.toDateString() === today.toDateString();
    //         case "Cette semaine":
    //             const startOfWeek = new Date(today);
    //             startOfWeek.setDate(today.getDate() - today.getDay());
    //             const endOfWeek = new Date(startOfWeek);
    //             endOfWeek.setDate(startOfWeek.getDate() + 6);
    
    //             return productDateObj >= startOfWeek && productDateObj <= endOfWeek;
    //         case "Ce mois":
    //             const isSameMonth = productDateObj.getMonth() === today.getMonth();
    //             const isSameYear = productDateObj.getFullYear() === today.getFullYear();
    //             return isSameMonth && isSameYear;
    //         case "Cette année":
    //             return productDateObj.getFullYear() === today.getFullYear();
    //             case "2019":
    //                 return productDateObj.getFullYear() === 2019;
    //             default:
    //                 return true;
    //     }
    // };
    
    // useEffect(() => {
    // setLoading(true);
    //     setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
    //     AfficheProductsService().getProduct()
    //         .then((res: any) => {
    //             let filtered = res.data.alldata.filter((pro: ArticleInfo) => {
    //                 const searchTerm = search.toLowerCase();
    //                 const designation = pro.Designation.toLowerCase();
    //                 const designationAr = pro.DesignationAr?.toLowerCase();
    //                 const refArticle = pro.RefArticle.toString().toLowerCase();
    //                 const sub = pro.LibelleSubstitut?.toString().toLowerCase();
    //                 const productPrice = parseFloat(pro.PrixVenteArticleHT);
    //                 const isSearchMatch =
    //                     searchTerm === "" ||
    //                     designation.includes(searchTerm) ||
    //                     designationAr?.includes(searchTerm) ||
    //                     refArticle.startsWith(searchTerm) ||
    //                     sub?.startsWith(searchTerm);
    //             });
    //             setFilteredProducts(filtered);
    //             setState((prevState: any) => ({ ...prevState, product: res.data.data }));
    //         })
    //         .catch((msg: any) =>
    //             setState((prevState: any) => ({
    //                 ...prevState,
    //                 messageErrors: msg.messageErrors,
    //             }))
    //         );
    //         setLoading(false);

    // }, [search]);
    
    const categories = ["Pièces automobiles", "Constructeur", "Maintenance et réparation", "Outils industriels", "Equipements", "Lubrifiants", "Huiles"];
    const [selectedCategory, setSelectedCategory] = useState<string | null>("Tous");
  
    const handleCheckboxChange = (category: string) => {
      setSelectedCategory((prev) => (prev === category ? null : category)); // Toggle selection
    };

    
    const handlePredefinedRangeChange = (range: [number, number]) => {
      setPriceRange(range);
      setCustomRange({ min: "", max: "" }); // Reset custom range
    };
    const handleCustomRangeChange = (type: "min" | "max", value: string) => {
      setCustomRange({ ...customRange, [type]: value });
      setPriceRange([parseInt(customRange.min || "0"), parseInt(customRange.max || "500000")]);
    };
    const { product, messageErros } = state;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
        pageNumbers.push(i);
    }
    return <>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <div>
        <div className="u-uppart-visiteur">
      <p style={{marginTop:"0.5%"}}>Bienvenue sur la boutique en ligne Merchsentry</p>
      <div className="select-lang-om">
      <select style={{marginTop:"0.5%"}} className="mx-3">
          <option value="eng">Eng</option>
          <option value="fr">Fr</option>
          <option value="ar">Ar</option>
     
        </select>
        <select style={{marginTop:"0.5%"}} className="mx-3">
          <option value="usd">USD</option>
          <option value="mad">MAD</option>

        </select>
      </div>
    </div>
    <div className="u-uppart-second">
      <h2>MERCHSENTRY</h2>
    </div>
        <div className="container row filter-Visiteur" style={{marginLeft:"13%"}}>
              
              <h6 className='up-text-title mt-4 mb-3'>Vous ne trouvez pas ce que vous cherchez? <Link className="csw" to={" "} style={{color:'#2DA5F3'}}> contactez nous par whatsapp</Link></h6>
          <div className="part-search-trier" style={{marginLeft:"15%"}}>
            <div className="search-new">
            <div className=""style={{ width: '28vw' }}>
                  <i className="bi bi-search position-absolute" style={{fontSize: "1.4rem", transform: 'translateY(-50%)',marginLeft:"26%",marginTop:"1.4%" }} />
                  <input
                    type="text"
                    onChange={handleSearch}
                    className="form-control w-100"
                    placeholder="Recherch un produit, ref .."
                  />
                </div>
            </div>
            <div style={{marginRight:"10%" , width:"21%"}} className="trier-par">
              Trier par 
            <select name="" id="" className='mx-2'>
              <option value="">meilleur ventes</option>
              <option value="">les plus vendus </option>
  
            </select>
            </div>
            </div>
  
            <div className="filter-result mt-3"style={{marginLeft:"15%"}} >
            <h6>
  filter actif :{" "}
  <b style={{ cursor: "pointer" }}>
    {selectedCategory !== "" ? (
      <>
        {selectedCategory}{" "}
        <i
          className="bi bi-x-lg"
          onClick={() => setSelectedCategory("Tous")} // Reset to "Tous" when the close icon is clicked
          style={{ cursor: "pointer" }}
        ></i>
      </>
    ) :
      <b>tous</b>
    }
  </b>
</h6>
             <h6 style={{marginRight:"10%"}}><b>{alldata && alldata.length >0 ? alldata.length :"0"}</b> resultas trouvés</h6>
  
            </div>
        </div>
        <div className="content-visiteure">
          
        <div className="categorie">
      <h5 className="mb-3">CATEGORIE</h5>
      {categories.map((category, index) => (
        <div className="category-checkbox" key={index}>
          <label>
            <input
              type="checkbox"
              name="category"
              value={category}
              checked={selectedCategory === category}
              onChange={() => handleCheckboxChange(category)}
            />
            <span className="checkbox-custom"></span>
            <span className="checkbox-label">{category}</span>
          </label>
        </div>
      ))}
      <div className="filter-price-range">
        <h5 className="mb-3 mt-3">GAMME DE PRIX </h5>
        <div className="custom-range mb-3">
          <input
            type="number"
            placeholder="Min prix"
            value={customRange.min}
            onChange={(e) => handleCustomRangeChange("min", e.target.value)}
          />
          <input
            type="number"
            placeholder="Max prix"
            value={customRange.max}
            onChange={(e) => handleCustomRangeChange("max", e.target.value)}
          />
        </div>
        <div className="predefined-ranges">
          {predefinedRanges.map((range) => (

        <div className="category-checkbox" key={range.label}>
          <label>
            <input
                type="checkbox"
                name="price-range"
              checked={priceRange[0] === range.value[0] && priceRange[1] === range.value[1]}
              onChange={() => handlePredefinedRangeChange(range.value)}
              />
            <span className="checkbox-custom"></span>
                            {range.label}

            {/* <span className="checkbox-label">{range.label}</span> */}
          </label>
        </div>

            // <label key={range.label}>
            //   <input
            //     type="checkbox"
            //     name="price-range"
            //     checked={priceRange[0] === range.value[0] && priceRange[1] === range.value[1]}
            //     onChange={() => handlePredefinedRangeChange(range.value)}
            //   />
            //   {range.label}
            
            // </label>
          ))}
        </div>
      </div>
    </div>
           
            {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{marginLeft:"27%", height: '250px', color: "#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
            <span className="loader-charg"> Chargement..</span> 
        </div>
        ) : (
            <div className="produit-content">

                <div className="row" style={{marginLeft:"25px" , marginTop:"-5%"}}>
      {currentRecords.length>0 ? currentRecords.map((pro) => (
        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.id}>
          <Link className="product-link" to={`/visiteurs/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off">
              <span className='off'>
                25% OFF
              </span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
            <div className="u-card-icons">
      <i className="bi bi-suit-heart u-heart"></i>
      <i className="bi bi-cart u-card-shopping"></i>
      </div>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
            <p className="u-card-ref-ar mb-3">Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
          </div>
          </Link>

        </div>
        
      )):<div className="no-produit">
      <i className="bi bi-emoji-neutral"></i><br />
      <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
    </div>
    }
                <div className="pagination mb-5">
<Link style={{marginLeft:"-30%"}} to ={`/visiteurs/articles`}>
              <button
                className="page-button"
                
              >
                Voir plus <i className="bi bi-arrow-right-short"></i>
              </button>
</Link>

        </div>
    </div>
                {/* {filteredProducts && filteredProducts.length > 0 ?
                    <div className="container_slide">
                        <CardContainer cards={filteredProducts} />
                    </div>
                    : product && product.length > 0 ? (
                        <div className="no-produit">
                            <i className="bi bi-emoji-neutral"></i><br />
                            <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p><br />
                        </div>
                    ) : (
                        <div className="no-produit">
              <i className="bi bi-emoji-neutral"></i><br />
              <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
            </div>
                    )} */}

                {/* <Swiper
                    style={{ transform: 'translate3d(' + 1 + 'px, 0px, 0px)' }}
                    freeMode={true}
                    grabCursor={true}
                    className="container myswiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        550: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        700: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                </Swiper> */}

            </div>
        )}
        </div>
        {/* <h5 className="prouit-p-v">Produits les plus <span className="vendus-page">vendus <i className="bi bi-arrow-up-circle-fill"></i> </span> </h5>
        {loading===true?(
            <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
          <div className="spinner-border custom-spinner" role="status">
          </div>
              <span className="loader-charg"> Chargement..</span>
        </div>
        ):(
          <>
        
        <div className="pagination mb-5">
<Link to ={`/visiteurs/articles`}>
              <button
                className="page-button"
              >
                Voir plus 
              </button>
</Link>

        </div>
          </>
        )
        } */}
            
        
        <Link to={"/visiteurs/panier"}>
        <div className="panier-visiteur-page" >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
            <path style={{color:"black"}} d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
            </svg>
            {/* {localStorageData?.length > 0 ? <span className="num">{localStorageData?.length}</span> : ""} */}

            </div>
        </Link>
        </div>
    </>
}


