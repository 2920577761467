import { Link } from "react-router-dom";
import "./notification.css"
import Sidebare from "../Sidbare/Sidebare";
import { NotificationMagasinType } from "../../../Modeles/NotificationModel";
import { useEffect, useState } from "react";
import GetNotificationAdmin from "../../../Services/Admin/GetNotificationAdmin";
import Strip from "../../../components/Strip";
export interface NotificationType {
    Notifications: NotificationMagasinType[],
    messageErrosNotif: string,
  }
export default function NotificationAdmin(){
    // ----------------------- getnotification -----------------------
const [stateNotification , setStateNotification] = useState<NotificationType>({
    Notifications:[] as NotificationMagasinType[],
    messageErrosNotif : "accune Notification",
    
    })
    useEffect(()=>{
      setStateNotification({...stateNotification })
          GetNotificationAdmin().GetNotification()
          .then((res)=>setStateNotification({...stateNotification  , Notifications:res.data})
    
          )
          .catch(msg=>setStateNotification({...stateNotification  , Notifications:msg.messageErrosNotif}))
    },[]);
    const {Notifications , messageErrosNotif} = stateNotification
    return <>
        <Sidebare/>
<Strip/>
        <div className="container">
            <h2 className="mt-5 mb-5">Notifications</h2>
            {Notifications.length>0 ? Notifications.map(notif=>(
                <Link className="derectionAdmin" to={notif.url}>
                    <h5>{notif.Date}</h5>
                <div className="card mb-5">
                    <span>{notif.Notification_Title}</span>
                    <div className="message">
                    <p>
                        {notif.Notification_Content}
                    </p>
                    <i className="bi bi-arrow-up-right-circle-fill"></i>
                    </div>
                    <div className="icon">
                    </div>
                </div>
                </Link>
            )):<h4>Accune Notification</h4>}
            
        </div>
    </>
}