import SideBarMobile from "../Sidbare/SideBarMobile";
// import Slide from "./01-img-refaccionarias-servicios-mejoresautos 1.png"

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import AfficheMagasinsService from "../../../Services/Magasin/AfficheMagasinsService";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode,Navigation } from 'swiper/modules';
export interface ProductType {
    product: ArticleInfo[],
    messageErros: string,
  }
  
  export interface ProductTypeMagasins {
    magasins: MagasinInfo[],
    messageErrosMagasin: string,
  }  
export default function HomeAdminMobile(){
    const [states , setStates] = useState<ProductTypeMagasins>({
      magasins: [] as MagasinInfo[],
      messageErrosMagasin : "Il n'y a pas de magasin",
    });
    const [search, setSearch] = useState("");
    const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 8;
    const [products, setProducts] = useState<ArticleInfo[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const handlePageChange = (pageNumber: number) => {
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    };
    const fetchProducts = (pageNumber: number) => {
      setLoading(true);
      AfficheProductsService().getProductPagination(pageNumber)
        .then((res: any) => {
          setProducts(res.data.data);
          setAlldata(res.data.alldata); // Save all data for searching
          setTotalPages(res.data.last_page);
          setLoading(false);
        })
        .catch((err: any) => {
          console.error("Error fetching products: ", err);
          setLoading(false);
        });
    };
    useEffect(() => {
      fetchProducts(currentPage);
    }, [currentPage]);
    
    useEffect(() => {
      AfficheMagasinsService().getMagasin()
        .then((res) => setStates({ ...states, magasins: res.data }))
        .catch(msg => setStates({ ...states, messageErrosMagasin: msg.messageErrosMagasin }));
    }, []);  
  
    const { magasins, messageErrosMagasin } = states;
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value =setSearch(e.target.value.trim());
      console.log("Search value:", value);
  
  }; 
    // ------------- State for Products ------------------
    const [state, setState] = useState<ProductType>({
      product: [] as ArticleInfo[],
      messageErros : "Il n'y a pas de produit",
    });
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      setLoading(true);
      AfficheProductsService().getProduct()
        .then((res) => {
          setState({ ...state, product: res.data.data});
          setLoading(false);
        })
        .catch((msg) => {
          setState({ ...state, messageErros: msg.messageErros });
          setLoading(false);
        });
    }, []);
    const filteredProducts = alldata.filter((pro: ArticleInfo) => {
      // Ensure RefArticle is an array
      const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
    
      return (
        pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
        pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
        refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) || // Check all references
        pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
      );
      
    });
    const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
  
    const { product, messageErros } = state;
  
    console.log(alldata.length); 
    return<>
    <SideBarMobile/>
    <div className="bienvenu">
      <h5>Bienvenu</h5>
      <p>Vous pouvez gérer votre stock en simplicité </p>
    </div>
    <div className="container-fluid ProductSlide mt-1">

        {/* Loader */}
        {loading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
    <div className="spinner-border custom-spinner" role="status"></div>
    <span className="loader-charg">Chargement</span>
  </div>
) : currentRecords.length > 0 ? (
  <div className="container mobile-article-card">
    <div className="produit-available">
        <p>Vous avez + de {alldata && alldata.length >0 ? alldata.length :"0"} Produits</p>
        <Link to="" className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
    </div>
        <div className="row" style={{width:"800px",marginTop:"-20%" , marginLeft:"-70%",transform:'scale(0.5)'}}>

    <Swiper
    freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        380: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
      >
      {currentRecords.map((pro) => (
        <SwiperSlide>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.id}>
          <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off">
              <span className='off' >
                25% off
              </span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
            <div className="u-card-icons">
      <i className="bi bi-suit-heart u-heart"></i>
      <i className="bi bi-cart u-card-shopping"></i>
      </div>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title" >{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
            <p className="u-card-ref-ar mb-2" >Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
          </div>
          </Link>
        </div>
            </SwiperSlide>
      )
      )
      }
    </Swiper>
    </div>
  </div>
) : (
  <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
        </div>
)}
<div style={{marginTop:"-20%"}}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color:"#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
            <>
            <div className="magasin-available">
        <p>Vous avez + de 20 magasins</p>
        <Link to="" className="vrp">Voir plus<i className="bi bi-arrow-right-short"></i></Link>
    </div>
        <div className="mt-3 position-relative container-fluid ProductSlide w-75" style={{width:"100px" ,marginLeft:"-10%",transform:'scale(0.8)'}}>
            
          <Swiper
          style={{width:"600px"}}
            freeMode={true}
            grabCursor={true}
            className="container myswiper"
            breakpoints={{
              0: { slidesPerView: 2, spaceBetween: 10 },
              380: { slidesPerView: 2, spaceBetween: 10 },
            }}
            autoplay={{
              delay: 2300, 
              disableOnInteraction: false,
            }}
            navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
            modules={[Autoplay, FreeMode,Navigation]}
          >
            {magasins && magasins.length > 0 ?
              magasins.map(mag => (
                <>
                <SwiperSlide key={mag.IdMagasin}>
                  <Link  style={{textDecoration:'none'}} className="" to={`/magasin/${mag.IdMagasin}/edit`}>
                  <div className="card-u-magasin-affiche">
                  <h2 className='u-n-m'>{mag.NomMagasin}</h2>
                <p className='u-para-mag'><i className="bi icoloc bi-geo-alt-fill"></i >{mag.adresse_de_siège.split(' ')[0]}
                  <h6 className='u-dsc-m'>{mag.adresse_de_siège.split(',')[1]?.trim() || 'casablanca'}</h6>

                </p>
                  </div>
                  </Link>
                </SwiperSlide>
                  
               
              </>
              )) : <h5 className="text-center">{messageErrosMagasin}</h5>}
          </Swiper>
              <div style={{color:"transparent",marginLeft:"1%"}}  className="swiper-button-prev">
                <i className="bi bi-chevron-left"></i>
              </div>
              <div style={{color:"transparent",marginRight:"-78%"}}  className="swiper-button-next">
                <i style={{color:"white"}}  className="bi bi-chevron-right"></i>
              </div>
        </div>
        </>
        )}
        </div>
        </div>
    </>
}