import { Navigate, useNavigate, useParams } from "react-router-dom";
import ModifierProduit from "../../Views/Admin/HomeAdmin/ModifierProduit";
import { FormEvent, SetStateAction, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import EditeMagasin from "../../Views/Admin/magasin/EditeMagasin";

export default function EditeMagasinService(){
    const navigate = useNavigate();
    const {id}= useParams();    
    const [Magasin,setMagasin] = useState<string>("");
    const [Nom_prprietaire,setNom_prprietaires] = useState<string>("");
    const [email,setemail] = useState<string>("");
    const [tele,settele] = useState<string>("");
    const [password,setpassword] = useState<string>("");
    const [adresse_de_siège,setadresse_de_siège] = useState<string>("");
    const [Message,setMessage] = useState<string>("");

    const handleSubmitUpdate=async(e: FormEvent)=>{
      e.preventDefault();
      const formData = new FormData();
    formData.append("_method", 'post');
    formData.append("nommagasins", Magasin);
    formData.append("nom_proprietaire", Nom_prprietaire);
    formData.append("email", email);
    formData.append("tele", "");
    formData.append("password", password);
    formData.append("adresse_de_siège", adresse_de_siège);

    await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/magasins/${id}/edit` , formData)
      .then(({data})=>{
        setMessage('magasin modifié avec succès')
        const timeoutId = setTimeout(() => {
            setMessage('')
            navigate("/magasin")
          }, 2000);
      })
  
        
    }
    // const fetchProduct=async()=>{
    //     try {
    //       await axios.get(process.env.REACT_APP_PHP_APP_URL + '/articles/' + id)
    //       .then(({data})=>{
    //             setDesignation(data.article.Designation);
    //             setPrixVenteArticleTTC(data.article.PrixVenteArticleTTC);
    //             setstock(data.article.stock);
    //             setDescription(data.article.Description);
    //       });
    //     } catch (error: AxiosError | any) {
    //       if (error.response?.status === 442) {
    //       console.log("good data");

    
    //       } else {
    //         console.log("error");
    //       }
    //     }
          
    //   }

    return<>
    <EditeMagasin
        Message={Message}
        setMessage={setMagasin}
        Magasin={Magasin}
        Nom_prprietaire={Nom_prprietaire}
        email={email}
        tele={tele}
        settele={settele}

        password={password}
        adresse_de_siège={adresse_de_siège}
        setMagasin={setMagasin}
        setNom_prprietaires={setNom_prprietaires}
        setemail={setemail}
        setpassword={setpassword}
        setadresse_de_siège={setadresse_de_siège}
        handleSubmitUpdate={handleSubmitUpdate} Designation={""} PrixVenteArticleTTC={""} Description={""} image={null} RefArticle={""} Unite={""} setDesignation={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setNomClient={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setAdresse={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setTotalCommandeHT={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setTotalRemise={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setPrixVenteArticleTTC={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setDescription={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setImage={function (value: SetStateAction<File | null>): void {
          throw new Error("Function not implemented.");
        } } setstock={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setRefARticle={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setUnite={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_2_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_3_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setprix_ht_1_magasin={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } } setquantité={function (value: SetStateAction<string>): void {
          throw new Error("Function not implemented.");
        } }    
    />
    </>
}