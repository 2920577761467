import Sidebare from "../Sidbare/Sidebare";
import { Link, useParams } from "react-router-dom";
import E1 from "../IMG/3.png"
import profil from "../IMG/defaultProfil.png"
import E2 from "../IMG/e1.png"
import E3 from "../IMG/e2.png"
import E4 from "../IMG/e3.png"
import Sponsor1 from "../IMG/s3.png"
import {Swiper , SwiperSlide} from"swiper/react"
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { useContext, useEffect, useState } from "react";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { CommercialInfo } from "../../../Modeles/Commercial";
import AfficheCommercial from "../../../Services/Magasin/AfficheCommercial";
import AfficheCommercialMagasinInAdmin from "../../../Services/Admin/AfficheCommercialMagainInAdmin";
import { map } from "jquery";
import AfficheClientMagasinInAdmin from "../../../Services/Admin/AfficheClientMagasinInAdmin";
import { ClientInfo } from "../../../Modeles/Client";
import HistoriqueMagasinService from "../../../Services/Magasin/HistoriqueMagasinService";
import { Commande } from "../../../Modeles/Commande";
import AfficheCommandeByMagasin from "../../../Services/Admin/AfficheCommandeByMagasin";
import MagasinInfoService from "../../../Services/Magasin/MagasinInfo";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import { info } from "console";
import { MagasinContext } from "../../../Context/MagasinContext";
import axios, { AxiosError } from "axios";
export interface ProductType{
  product : ArticleInfo[],
  messageErros:string,
}
export interface CommercialType{
  commercial : CommercialInfo[],
  messageErrosCommercial:string,
}
export interface ClientType{
  Client : ClientInfo[],
  messageErrosClient:string,
}
export interface CommandeType{

  Commande : Commande[],
  messageErrosCommande:string,
}
export interface MagasinType{
  Info : MagasinInfo[],
  messageErrosInfo:string,
}
const EditeMagasin:React.FC<ArticleInfo> = ({
    Magasin,
    Nom_prprietaire,
    email,
    password,
    adresse_de_siège,
    setMagasin,
    setNom_prprietaires,
    setemail,
    tele,
    settele,
    setPrixVenteArticleTTC,
    setpassword,
    setadresse_de_siège,
    handleSubmitUpdate,
    Message,
    setMessage,
  }) => {

  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('MagasinId');
  const panier = localStorage.getItem('paniermagasin');
    const idmagasin = MagasinId || magasinContext.id?.id;
const { id } = useParams();
// -------------------------- affiche Info Magasin --------------------
const [stateInfo, setInfo] = useState<MagasinType>({
  Info: [] as MagasinInfo[],
  messageErrosInfo: "Accune info",
});
useEffect(()=>{
  setState({...state })
  MagasinInfoService(id).getMagasinInfo()
      .then((res)=>setInfo({...stateInfo  , Info:res.data})

      )
      .catch(msg=>setInfo({...stateInfo  , Info:msg.messageErrosCommande}))
},[]);
const {Info , messageErrosInfo} = stateInfo
// -------------------------- affiche commande by magasin --------------------
const [stateCommande, setstateCommande] = useState<CommandeType>({
  Commande: [] as Commande[],
  messageErrosCommande: "Accune commande",
});
useEffect(()=>{
  setState({...state })
    AfficheCommandeByMagasin(id).GetCommande()
      .then((res)=>setstateCommande({...stateCommande  , Commande:res.data})

      )
      .catch(msg=>setstateCommande({...stateCommande  , Commande:msg.messageErrosCommande}))
},[]);
const {Commande , messageErrosCommande} = stateCommande
// ------------------------affiche client by id magasin ---------------------------
const [stateClient, setstateClient] = useState<ClientType>({
  Client: [] as ClientInfo[],
  messageErrosClient: "Accune commercial",
});

useEffect(() => {
  setState({ ...state });
  AfficheClientMagasinInAdmin(id).GetClient()
  .then((res) => setstateClient({ ...stateClient, Client: res.data }))
  .catch(msg => setstateClient({ ...stateClient, Client: msg.messageErrosClient }));
}, []);

const { Client, messageErrosClient } = stateClient;


//  --------------------- affiche commercial by id magasin -----------------------
const [stateCommercial, setStateCommercial] = useState<CommercialType>({
  commercial: [] as CommercialInfo[],
  messageErrosCommercial: "Accune commercial",
});

useEffect(() => {
  setState({ ...state });
  AfficheCommercialMagasinInAdmin(id).GetCommercial()
    .then((res) => setStateCommercial({ ...stateCommercial, commercial: res.data }))
    .catch(msg => setStateCommercial({ ...stateCommercial, commercial: msg.messageErrosCommercial }));
}, []);

const { commercial, messageErrosCommercial } = stateCommercial;
  

  const [state , setState] = useState<ProductType>({
    product:[] as ArticleInfo[],
    messageErros : "accune produit",

})
const [stateMagasin , setStateMagasin] = useState<ProductType>({
  product:[] as ArticleInfo[],
  messageErros : "accune produit",

})
const [loading, setLoading] = useState(true);

const bloqué=async (e:any,id:any):Promise<any>=>{
  console.log(id)
  e.preventDefault();
  const formData = new FormData();
  formData.append("id", id);
  try {
    await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/bloque-magasin`, formData)
    .then(({data})=>{
      window.location.reload();
    });
  } catch (error: AxiosError | any) {
    } 
}
// -------------affiche magasin info by id -----------------
useEffect(() => {
  setState({ ...state }); 
  AfficheProductsService()
    .getProduct()
    .then((res) => {
      setState({ ...state, product: res.data.data });
      setLoading(false); 
    })
    .catch((msg) => {
      setState({ ...state, product: msg.messageErrors });
      setLoading(false);
    });
}, []);
const {product , messageErros} = state
const handleGoBack = () => {
  window.history.back();
};
console.log(Info);
 // const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
// const indexOfLastRecord = currentPage * recordsPerPage;
// const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
// const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
    return<>
    <Sidebare/>
      {
      Info &&
  Info.length>0 ? Info.map(inf=>(
   
    <div className="container mt-5">
      
    <div className="barRetour">
      <span className="iconRetour">
        <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
        
      </span>

      <div className="marque">

    <h4> {inf.NomMagasin}</h4>
    <h5>{inf.status === "active" ? <h5>Active <i className="actif bi bi-circle-fill"></i></h5> : <h5>bloqué <i className="text-danger actif bi bi-circle-fill"></i></h5> }</h5>
</div>

    </div>
    <form action="" onSubmit={handleSubmitUpdate}>

    <div className="inputForm">
    {
Message!=""?
      
<div className="alert alert-success mb-5" role="alert">
  <h5><i className="bi bi-check2-circle"></i> {Message}</h5>
</div>
:
""
       }
    <div className="row mb-3">
  <div className="col">
        <span>Nom de magasin</span>
    <input onChange={(e)=>setMagasin(e.target.value)} type="text" value={Magasin || inf.NomMagasin} className="form-control" id="nom" aria-label="First name" />
  </div>
  
  <div className="col-7" id="inputTele">
    <div className="inputTele">

  <span>Nom complet de proprietaire</span>
    <input  onChange={(e)=>setNom_prprietaires(e.target.value)} value={Nom_prprietaire || inf.Nom_complet_propriétaire} type="text" className="form-control" id="tele"  />
    </div>

  </div>
</div>
<div className="row mb-3">
  <div className="col">
        <span>Email</span>
    <input  onChange={(e)=>setemail(e.target.value)} type="text" value={email || inf.email} className="form-control" id="nom" aria-label="First name" />
  </div>
  
  <div className="col-7" id="inputTele">
    <div className="inputTele">

  <span>mot de passe</span>
    <input  onChange={(e)=>setpassword(e.target.value)} type="text" value={password || inf.password}  className="form-control" id="tele"  />
    </div>

  </div>
</div>
<div className="row mb-3">
  <div className="col">
        <span>Numero de telephone</span>
    <input  onChange={(e)=>settele(e.target.value)} type="text" value={tele || inf.Tele} className="form-control" id="nom" aria-label="First name" />
  </div>
  
  <div className="col-7" id="inputTele">
    <div className="inputTele">

  <span>ville</span>
    <input  onChange={(e)=>setadresse_de_siège(e.target.value)} type="text" value={adresse_de_siège || inf.adresse_de_siège}  className="form-control" id="tele"  />
    </div>

  </div>
</div>
<div className="col-12">
<span>Adresss</span>
<input  onChange={(e)=>setadresse_de_siège(e.target.value)} type="text" value={adresse_de_siège || inf.adresse_de_siège}  className="form-control" id="inputAddress"  />

</div>

     <div className="text-center mt-3">
     <Link className="bloque" to="" onClick={(e) => bloqué(e, id)}><h6
        className="mb-5"
        style={{
          color: inf.status === 'active' ? 'red' : inf.status === 'bloqué' ? 'green' : 'inherit'
        }}
      >{inf.status === "active" ? "bloqué ce magasin": inf.status === "bloqué" ? "active magasin" : ""}
     <i className="bi bi-dash-circle"></i></h6></Link>
 <button className="btnChangeInfo mx-auto">Change les infos </button>
</div>
    </div>
    </form>
<div className="categorieSlide">
<div className="row mt-5" style={{ marginLeft: "30px", scale: "0.9" }}>
  {loading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
      <div className="spinner-border custom-spinner" role="status"></div>
      <span className="loader-charg">Chargement des articles</span>
    </div>
  ) : product.length > 0 ? (
    product.slice(0, 4).map((pro) => (
      <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.id}>
        <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off">
              <span className='off'>25% off</span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img"/>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title">
              {pro.Designation}
              {pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}
            </div>
            <p className="u-card-ref-ar">Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
          </div>
        </Link>
      </div>
    ))
  ) : (
    <div className="no-produit">
      <i className="bi bi-emoji-neutral"></i>
      <br />
      <p>Il n'y a pas de produit pour cette magasin</p>
      <br />
      <Link
        to={"https://api.whatsapp.com/send?phone=212661718081"}
        target="_blank"
        className="botton-remplir"
      >
        <button>
          <i className="bi bi-whatsapp"></i>Contactez-nous
        </button>
      </Link>
    </div>
  )}
</div>


</div>
<div className="magasin-commande">
<h5 className="mt-3 mb-5 text-center">List des Commandes</h5>

<table className="table table-bordered mt-3 mb-5">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">Statut</th>
        <th scope="col">Date</th>
        <th scope="col">Montant</th>

      </tr>
    </thead>
    <tbody>
    {Commande && Commande.length > 0 ? (
  Commande.slice(0, 3).map(com => (
    <tr key={com.IdCommande}>
      <td>#{com.IdCommande}</td>
      <td style={{ color: com.Statut === 'confirme' ? '#1fa750' : com.Statut === 'annuler' ? '#df2222' : "#cfa00c",
           }}>{com.Statut
        }</td>
      <td>{com.DateCommande}</td>
      <td>{com.TotalCommandeHT} MAD</td>
    </tr>
  ))
) : (
  <div className="row">
    <div className="col text-center">
      <h6><i className="bi bi-info"></i> {messageErrosCommande}</h6>
    </div>
  </div>
)}
    </tbody>

  </table>
    {Commande.length>0?<Link to={"/historiques"} className="vopc">Voire plus</Link>:""}
</div>

<div className="row part-list-commercials">
  <h5>
  List commercials
  </h5>
{
  commercial && commercial.length>0 ? commercial.slice(0, 2).map(com=>(
    <div className="upcard ms-3" style={{ width: '48%' }}>
    <div className="image-et-nom">
    <img src={profil} alt="" />
    <h2 className="mb-3">{com.Nom} {com.prenom}</h2><br />
    <p>{com.ville} </p>
    </div>
    <div className="body-content">
      <h3>Email :  {com.email}</h3>
      <h3>Mote de passe :{com.password}</h3>
      <h3>Téléphone : {com.télephone} </h3>

    </div>
  <Link to={`/magasin/${inf.id}/commercial/${com.id}`} className="vp-edit">Voir plus <i style={{marginLeft:"1%"}} className="bi bi-box-arrow-up-right"></i></Link>
  </div>
  )):
  <div className="row">
    <div className="col text-center">
      <h6><i className="bi bi-info"></i> {messageErrosCommercial}</h6>
    </div>
  </div>
  }
  <Link to="" style={{color:"#FA8232"}} className="vp-edit mb-3 mt-3">Voir plus</Link>
</div>
<div className="row part-list-commercials">
<h5>
List clients
  </h5>
{
  Client && Client.length>0 ? Client.slice(0, 2).map(cl=>(
    <div className="upcard ms-3" style={{ width: '48%' }}>
    <div className="image-et-nom">
    <img src={profil} alt="" />
    <h2 className="mb-3">{cl.NomClient} {cl.PrenomClient}</h2><br />
    <p>{cl.Ville} </p>
    </div>
    <div className="body-content">
      <h3>Email :  {cl.EmailClient}</h3>
      <h3>Mote de passe :{cl.PasswordClient}</h3>
      <h3>Téléphone : {cl.NumTele} </h3>

    </div>
  <Link to={`/magasin/${inf.id}/client/${cl.IdClient}`} className="vp-edit"> Voir plus <i style={{marginLeft:"1%"}} className="bi bi-box-arrow-up-right"></i> </Link>
  </div>
  )):
  <div className="row">
    <div className="col text-center">
      <h6><i className="bi bi-info"></i> {messageErrosClient}</h6>
    </div>
  </div>
  }
  <Link to="" style={{color:"#FA8232"}}  className="vp-edit mb-3 mt-3">Voir plus</Link>
</div>
</div>
)):""
}

    </>
}
export default EditeMagasin;