import React, { useState } from 'react';
import './NotFound.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const NotFound = () => {
  const Data = [
    // -----------1
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
     {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    {off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    // ----------------2
    { off: '25% Off', img: 'https://cimatti.ma/1316-home_default/jante-avant-mt-05.jpg', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://cimatti.ma/1316-home_default/jante-avant-mt-05.jpg', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    // --------------3
    { off: '25% Off', img: 'https://cimatti.ma/1316-home_default/jante-avant-mt-05.jpg', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
    { off: '25% Off', img: 'https://img.lovepik.com/bg/20231220/Stunning-Nighttime-Ferris-Wheel-Background-Image-Perfect-for-Your-Design_2479108_wh860.png', title: 'ROUE AV3208', REf: '134566777', Constructeur: 'Constructeur', price: '1200 MAD' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = Data.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(Data.length / recordsPerPage);

  const changePage = (page:any) => {
    setCurrentPage(page);
  };
  // Magasins Data-------------------
  const magasinsData = [
    {
      name: "Magasin1",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY123",
    },
    {
      name: "Magasin2",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY124",
    },
    {
      name: "Magasin3",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY125",
    },
    {
      name: "Magasin4",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY126",
    },
    {
      name: "Magasin5",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY123",
    },
    {
      name: "Magasin6",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY124",
    },
    {
      name: "Magasin7",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY125",
    },
    {
      name: "Magasin8",
      description: "Marché  Boutiques de souvenirs & Magasins ILLEURES boutiques",
      address: "boulvard XXXYYY126",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCards = 4; // Number of cards visible at a time

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % magasinsData.length
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - 1 + magasinsData.length) % magasinsData.length
    );
  };

  return (
    <div className="u-card-container">
      {/* Navbar----------------- */}
      <div className="u-navbar">
        <div className='u-navbar-content'>
      <span className='u-navbar-title'> Bienvenu sur le boutique en ligne Merchsentry </span> 
      <span  className='u-navbar-select'>
        <select >
          <option value="eng">Eng</option>
          <option value="fr">Fr</option>
          <option value="ar">Ar</option>
     
        </select>
        <select >
          <option value="usd">USD</option>
          <option value="mad">MAD</option>

        </select>
        </span>
        </div> 
      </div>

      <div className="u-img-nav">
        <h1 className='u-img-title'>  magasiins xxxxxxxx </h1>
         
       
      </div>
      <div className="u-content">
        {/* Category--------------------- */}
        <div className="u-category">
          <h2 className="u-category-title">Categorie</h2>
          <form className="u-categoryForm">
            {['Pièces automobiles', 'Constructeurs', 'Maintenance et réparation', 'Outils industriels', 'Equipements', 'Lubrifiants', 'Huiles'].map((category) => (
              <label key={category} className="u-category-item">
                <input type="radio" name="category" value={category} className="u-category-radio" />
                {category}
              </label>
            ))}
          </form>
        </div>

        {/* Main Content */}
        <div className="u-main-content">
          {/* Header--------------- */}
          <div className="u-Header">
            <h3 className="u-title">COMMANDES</h3>
            <div className="u-header-controls">
              <div className="u-search">
                <input type="text" placeholder="chercher un produit" className="u-input-search" />
                <span><i className="bi bi-search u-icon-search"></i></span>
                
              </div>
              <div className="u-select">
                <label className='mx-2'>Trier par</label>
                <select className="u-select-option">
                  <option value="option1" >Meilleur ventes</option>
                  <option value="option2">Prix croissant</option>
                  <option value="option3">Prix décroissant</option>
                </select>
              </div>
            </div>
            <div className="u-filtre">
              <span>
              Filtres <strong>Tous</strong> <i className="bi bi-x u-i-filter"></i>
              </span>
              <span>
               <strong>66788 </strong> Résultats trouvés
              </span>
          
            </div>
          </div>

          {/* Card List-------------- */}
          <div className="u-card-list">
           
            {records.map((data, index) => (
              <div key={index} className="u-card">
              
                <img src={data.img} alt={data.title} className="u-card-img" />
                <div className='u-km'>
                  <p>A 10 Km de chez vous</p>
                </div>
                <div className='u-star'>
                <i className="bi bi-star-fill u-i-star"></i>
                <i className="bi bi-star-fill u-i-star"></i>
                <i className="bi bi-star-fill u-i-star"></i>
                <i className="bi bi-star-fill u-i-star"></i>
                <i className="bi bi-star-fill u-i-star"></i>
                </div>
                <div className="u-card-title">{data.title}</div>
    
                <div className="u-card-price">{data.price}</div>
              </div>
            ))}
          </div>

          {/* Pagination------------------- */}
          <nav>
            <ul className="u-pagination">
              <li className="u-page-item">
                <button
                  className="u-page-link"
                  onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                    <i className="bi bi-arrow-left"></i>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className={`u-page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button
                    className="u-page-link"
                    onClick={() => changePage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className="u-page-item">
                <button
                  className="u-page-link"
                  onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                    <i className="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
