import { useEffect, useState } from "react";
import Sidebare from "../Sidbare/Sidebare";
import DemandesAdmin from "../../../Services/Admin/DemandesAdmin";
import { Link, useNavigate } from 'react-router-dom';
import { DemandeType } from "../../../Modeles/DemandeModel";
import axios, { AxiosError } from "axios";
import Strip from "../../../components/Strip";

export interface demande {
    demande: DemandeType[],
    messageErros: string,
}

export default function DemandeAdmin() {
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [state, setState] = useState<demande>({
        demande: [] as DemandeType[],
        messageErros: "Aucune demande",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [dateFilter, setDateFilter] = useState("");

    const itemsPerPage = 4;

    const active = async (e: any, id: any): Promise<any> => {
        e.preventDefault();
        const formData = new FormData();
        try {
            await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/demande/${id}/active`, formData)
            .then(({ data }) => {
                setMessage('Le magasin a été activée avec succès');
                setTimeout(() => {
                    navigate('/home');
                }, 3000);
            });
        } catch (error: AxiosError | any) {
            setMessageError('Erreur lors de l\'activation');
            setTimeout(() => setMessageError(''), 3000);
        }
    };

    const supprimer = async (e: any, id: any): Promise<any> => {
        e.preventDefault();
        try {
            await axios.get(`${process.env.REACT_APP_PHP_APP_URL}/demande/${id}/supprimer`)
            .then(({ data }) => {
                setMessageError('Le magasin a été supprimé avec succès');
                setTimeout(() => {
                    setMessageError('');
                    window.location.reload();
                }, 3000);
            });
        } catch (error: AxiosError | any) {
            setMessageError('Erreur lors de la suppression');
            setTimeout(() => setMessageError(''), 3000);
        }
    };

    const handleChangePage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDateFilter(e.target.value);
    };

    useEffect(() => {
        DemandesAdmin().GetCommande()
            .then((res) => setState({ ...state, demande: res.data }))
            .catch((msg) => setState({ ...state, demande: msg.messageErros }));
    }, []);

    const { demande, messageErros } = state;

    const filteredProducts = demande.filter((p) => {
        const matchesSearchTerm = p.nom.toLowerCase().includes(searchTerm.toLowerCase()) || 
                                  p.NomMagasin.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDateFilter = dateFilter === "" || (
            dateFilter === "Aujourduit" && new Date(p.date_creation).toDateString() === new Date().toDateString()
        ) || (
            dateFilter === "Hier" && new Date(p.date_creation).toDateString() === new Date(Date.now() - 864e5).toDateString()
        ) || (
            dateFilter === "Cette semaine" && new Date(p.date_creation) >= new Date(new Date().setDate(new Date().getDate() - 7))
        ) || (
            dateFilter === "Ce mois" && new Date(p.date_creation).getMonth() === new Date().getMonth()
        ) || (
            dateFilter === "Cette année" && new Date(p.date_creation).getFullYear() === new Date().getFullYear()
        );

        return matchesSearchTerm && matchesDateFilter;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = Array.from({ length: Math.ceil(filteredProducts.length / itemsPerPage) }, (_, i) => i + 1);

    return (
        <>
            <Sidebare />
<Strip/>
            
            <div className="container">
                <h3 className="mt-5 mb-5">Bonjour ! Consultez vos dernier demandes</h3>
                <div className="tableInfo">
                    {messageError && <div className="alert alert-danger" role="alert">{messageError}</div>}
                    {message && <div className="alert alert-success" role="alert">{message}</div>}

                    <div className="table-controls">
                        <div className="search-wrapper">
                            <input
                                type="text"
                                placeholder="Rechercher avec le nom o nom de magasin..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                          <i style={{position:"absolute" , fontSize:"1.3rem", marginLeft:"-5%", marginTop:"0.7%"}} className="bi bi-search"></i>
                        </div>
                        <select className="select-etat" onChange={handleDateFilterChange} value={dateFilter}>
                            <option value="">Tous</option>
                            <option value="Hier">Hier</option>
                            <option value="Aujourduit">Aujourd'hui</option>
                            <option value="Cette semaine">Cette semaine</option>
                            <option value="Ce mois">Ce mois</option>
                            <option value="Cette année">Cette année</option>
                        </select>
                    </div>

                    <table className="mt-5">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nom</th>
                                <th>Nom magasin</th>
                                <th>Adresse</th>
                                <th>Email</th>
                                <th>Mot de passe</th>
                                <th>Statut</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? currentItems.map((pro, index) => (
                                <tr key={index}>
                                    <td>{pro.id}</td>
                                    <td>{pro.nom}</td>
                                    <td>{pro.NomMagasin}</td>
                                    <td>{pro.adress}</td>
                                    <td>{pro.email}</td>
                                    <td>{pro.password}</td>
                                    <td style={{width:"11%"}}>
                                        <span className="s-tab-h" style={{
                                            color: pro.status === 'active' ? '#1fa750' : pro.status === 'non active' ? '#df2222' : "#cfa00c",
                                            backgroundColor: pro.status === 'active' ? '#cff6dd' : pro.status === 'non active' ? '#ffc9c9' : "#f3e9c8"
                                        }}>
                                            <b style={{ fontSize: "3rem" }}> .</b> {pro.status}
                                        </span>
                                    </td>
                                    <td>{pro.date_creation}</td>
                                    <td>
                                        <Link onClick={(e) => active(e, pro.id)} to="#"><button className="btn btn-success mb-1">Activer</button></Link>
                                        <br />
                                        <Link onClick={(e) => supprimer(e, pro.id)} to="#"><button className="btn btn-danger">Supprimer</button></Link>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan={9} className="text-center">Pas de Demande</td></tr>}
                        </tbody>
                    </table>

                    {pageNumbers.length > 1 && (
                        <nav>
                            <ul className="pagination" style={{ marginBottom: "15%" }}>
                                <li className="page-item">
                                    <button className="page-link" onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Précédent</button>
                                </li>
                                {pageNumbers.map((number) => (
                                    <li className="page-item" key={number}>
                                        <button className={`page-link ${currentPage === number ? 'activeButton' : ''}`} onClick={() => handleChangePage(number)}>{number}</button>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <button className="page-link" onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === pageNumbers.length}>Suivant</button>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>
            </div>
        </>
    );
}
