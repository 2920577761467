import "./SidebareMobile.css"
import logo from "../IMG/Logo.png"
import Slide from "./01-img-refaccionarias-servicios-mejoresautos 1.png"

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import AfficheMagasinsService from "../../../Services/Magasin/AfficheMagasinsService";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';  
export interface ProductType {
  product: ArticleInfo[],
  messageErros: string,
}

export interface ProductTypeMagasins {
  magasins: MagasinInfo[],
  messageErrosMagasin: string,
}
export default function SideBarMobile(){
    const [active, setActive] = useState<string>("home");
    const handleActive = (name: string) => {
      setActive(name);
    };
    const [states , setStates] = useState<ProductTypeMagasins>({
      magasins: [] as MagasinInfo[],
      messageErrosMagasin : "Il n'y a pas de magasin",
    });
    const [search, setSearch] = useState("");
    const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 8;
    const [products, setProducts] = useState<ArticleInfo[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const handlePageChange = (pageNumber: number) => {
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    };
    const fetchProducts = (pageNumber: number) => {
      setLoading(true);
      AfficheProductsService().getProductPagination(pageNumber)
        .then((res: any) => {
          setProducts(res.data.data);
          setAlldata(res.data.alldata); // Save all data for searching
          setTotalPages(res.data.last_page);
          setLoading(false);
        })
        .catch((err: any) => {
          console.error("Error fetching products: ", err);
          setLoading(false);
        });
    };
    useEffect(() => {
      fetchProducts(currentPage);
    }, [currentPage]);
    
    useEffect(() => {
      AfficheMagasinsService().getMagasin()
        .then((res) => setStates({ ...states, magasins: res.data }))
        .catch(msg => setStates({ ...states, messageErrosMagasin: msg.messageErrosMagasin }));
    }, []);  
  
    const { magasins, messageErrosMagasin } = states;
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value =setSearch(e.target.value.trim());
      console.log("Search value:", value);
  
  }; 
    // ------------- State for Products ------------------
    const [state, setState] = useState<ProductType>({
      product: [] as ArticleInfo[],
      messageErros : "Il n'y a pas de produit",
    });
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      setLoading(true);
      AfficheProductsService().getProduct()
        .then((res) => {
          setState({ ...state, product: res.data.data});
          setLoading(false);
        })
        .catch((msg) => {
          setState({ ...state, messageErros: msg.messageErros });
          setLoading(false);
        });
    }, []);
    const filteredProducts = alldata.filter((pro: ArticleInfo) => {
      // Ensure RefArticle is an array
      const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
    
      return (
        pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
        pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
        refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) || // Check all references
        pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
      );
      
    });
    const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
  
    const { product, messageErros } = state;
  
    console.log(alldata.length); 

    return<>
    {/* Start downBar  */}
    <div className="downbar">
    <div className="bar-mobile">
    <Link className="ic-mo" to={""}>
        <div
          className={`icon-wrapper ${active === "magasin" ? "icon-active-mobile" : ""}`}
          onClick={() => handleActive("magasin")}
          tabIndex={0} // For keyboard focus
        >
          <i className="bi bi-shop"></i>
        </div>
        </Link>
    <Link className="ic-mo" to={""}>

        <div
          className={`icon-wrapper ${active === "historique" ? "icon-active-mobile" : ""}`}
          onClick={() => handleActive("historique")}
          tabIndex={0} // For keyboard focus
        >
            <i className="bi bi-clock-history"></i>
        </div>
        </Link>
    <Link className="ic-mo" to={""}>

        <div
          className={`icon-wrapper ${active === "home" ? "icon-active-mobile" : ""}`}
          onClick={() => handleActive("home")}
          tabIndex={0} // For keyboard focus
        >
            <i className="bi bi-house-door-fill "></i>
        </div>
        </Link>
    <Link className="ic-mo" to={""}>

        <div
          className={`icon-wrapper ${active === "demande" ? "icon-active-mobile" : ""}`}
          onClick={() => handleActive("demande")}
          tabIndex={0}>
        <i className="bi bi-envelope-paper"></i><span className="count-demande-mobile">1</span>
        </div>
        </Link>
    <Link className="ic-mo" to={""}>

        <div
          className={`icon-wrapper ${active === "notification" ? "icon-active-mobile" : ""}`}
          onClick={() => handleActive("notification")}
          tabIndex={0} // For keyboard focus
        >
          <i className="bi bi-bell"></i>
        </div>
        </Link>
    </div>
    </div>
    <div className="bar-mobile">
        <div className="logo-mobile">
        <img src={logo} alt="" />
        </div>
        <div className="panier-tpoint-mobile " >
          <div className="count-panier-mobile">
          <span>1</span>
          </div>
          <div className="panier-point-icon">
            <i className="bi bi-cart"></i>
            <i className="bi bi-three-dots-vertical"></i>
          </div>
        {/* <div className="menu-admin-mobile">

        </div> */}
        </div>
    </div>
    {/* finish downBar  */}
    {/* Start Slide pub  */}
{/* <div className="slide-pub">
<h5>Explorez nos solutions </h5>
</div> */}

    {/* finish Slide pub  */}

    {/* finish Slide pub  */}

    
    {/* <div className="bienvenu">
      <h5>Bienvenu</h5>
      <p>Vous pouvez gérer votre stock en simplicité </p>
    </div>
    <div className="container-fluid ProductSlide mt-1">
        Loader
        {loading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
    <div className="spinner-border custom-spinner" role="status"></div>
    <span className="loader-charg">Chargement</span>
  </div>
) : currentRecords.length > 0 ? (
  <div className="container mobile-article-card">
        <div className="row" style={{width:"800px",marginTop:"10%" , marginLeft:"-65%",transform:'scale(0.5)'}}>

    <Swiper
    freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        380: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
      >
      {currentRecords.map((pro) => (
        <SwiperSlide>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.id}>
          <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off"style={{marginLeft:"-70%"}}>
              <span className='off' >
                25% off
              </span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
            <div className="u-card-icons">
      <i className="bi bi-suit-heart u-heart"></i>
      <i className="bi bi-cart u-card-shopping"></i>
      </div>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title" style={{fontSize:"0.9rem", display:'flex',justifyContent:"flex-start"}}>{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
            <p className="u-card-ref-ar mb-2" style={{display:'flex',justifyContent:"flex-start"}}>Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price" style={{display:'flex',justifyContent:"flex-start"}}>{pro.PrixVenteArticleHT} MAD</div>
          </div>
          </Link>
        </div>
            </SwiperSlide>
      )
      )
      }
    </Swiper>
    </div>
  </div>
) : (
  <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
        </div>
)}

  </div> */}



    {/* finish Slide pub  */}




    </>
}