import React, { useContext, useEffect, useState } from "react";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';
// import "/swiper/swiper-bundle.min.css";

import "./HomeMagasin.css";
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheArticleInMagasin from "../../../Services/Magasin/AfficheArticleInMagasin";
import { MagasinContext } from "../../../Context/MagasinContext";
import pMagasin from "../../Admin/IMG/3.png";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import Strip from "../../../components/Strip";

export interface ProductType {
  product: ArticleInfo[];
  messageErros: string;
}

export function HomeMagasin() {
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem("MagasinId");
  const id = MagasinId || magasinContext.id?.id;
  const localStorageData = JSON.parse(localStorage.getItem('products') || '[]');
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<ArticleInfo[]>([]);
  const [alldata, setAlldata] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchProducts = (pageNumber: number) => {
    setLoading(true);
    AfficheProductsService().getProductPagination(pageNumber)
      .then((res: any) => {
        setProducts(res.data.data);
        setAlldata(res.data.alldata); // Save all data for searching
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching products: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    console.log("salam");
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const categories = ["Pièces automobiles", "Constructeur", "Maintenance et réparation", "Outils industriels", "Equipements", "Lubrifiants", "Huiles"];
  const [selectedCategory, setSelectedCategory] = useState<string | null>("Tous");

  const handleCheckboxChange = (category: string) => {
    setSelectedCategory((prev) => (prev === category ? null : category)); // Toggle selection
  };
  // Filter products based on search term using alldata
  const filteredProducts = alldata.filter((pro: ArticleInfo) => {
    // Ensure RefArticle is an array
    const refs = Array.isArray(pro.RefArticle) ? pro.RefArticle : [pro.RefArticle];
  
    return (
      pro.Designation?.toLowerCase().includes(search.toLowerCase()) ||
      pro.PrixVenteArticleTTC?.toLowerCase().includes(search.toLowerCase()) ||
      refs.some(ref => ref?.toLowerCase().includes(search.toLowerCase())) || // Check all references
      pro.DesignationAr?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const paginatedProducts = filteredProducts.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
  return (
    <>
      <SideBareMagasin />
      
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
<>
<Strip/>
<div className="categ">
  <div style={{marginLeft:"19%", scale:"0.9"}}>

<div className="container part-search-trier">
          <div className="search-new">
          <div className=""style={{ width: '35vw' }}>
                <i className="bi bi-search position-absolute" style={{fontSize: "1.4rem", transform: 'translateY(-50%)',marginLeft:"38%",marginTop:"1.7%" }} />
                <input
                  type="text"
                  onChange={handleSearch}
                  className="form-control w-100"
                  placeholder="Recherch un produit, ref .."
                />
              </div>
          </div>
          
          <div className="trier-par">
            Trier par 
          <select name="" id="" className='mx-2'>
            <option value="">meilleur ventes</option>
            <option value="">les plus vendus </option>

          </select>
          </div>
          </div>
          <div className="filter-result mt-3">
          <h6>
  filter actif :{" "}
  <b style={{ cursor: "pointer" }}>
    {selectedCategory !== "" ? (
      <>
        {selectedCategory}{" "}
        <i
          className="bi bi-x-lg"
          onClick={() => setSelectedCategory("Tous")} // Reset to "Tous" when the close icon is clicked
          style={{ cursor: "pointer" }}
        ></i>
      </>
    ) :
      <b>tous</b>
    }
  </b>
</h6>            <h6><b>{alldata && alldata.length >0 ? alldata.length :"0"}</b> resultas trouvés</h6>

          </div>
      <br /><br />
  </div>
      <div className="content-visiteure" >
      <div className="categorie" style={{marginTop:"-35%", marginLeft:"6%"}}>
      <h5 className="mb-3">CATEGORIE</h5>
      {categories.map((category, index) => (
        <div className="category-checkbox" key={index}>
          <label>
            <input
              type="checkbox"
              name="category"
              value={category}
              checked={selectedCategory === category}
              onChange={() => handleCheckboxChange(category)}
            />
            <span className="checkbox-custom"></span>
            <span className="checkbox-label">{category}</span>
          </label>
        </div>
      ))}
    </div>
      {/* ----------------------------------- PRODUIT SLIDE ------------------------------------ */}
      <div className="container-fluid ProductSlide mt-1">
        <div className=" pavp">
          <div>
            {/* <p className="container pMagazin">Vous avez +5,000 produits</p> */}
          </div>
          <div>
            {/* <Link to={"/articles"} className="">Voir Plus <i className="bi bi-arrow-right-short" /></Link> */}
          </div>
        </div>

        {/* Loader */}
        {loading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '250px', color: "#FFC400" }}>
    <div className="spinner-border custom-spinner" role="status"></div>
    <span className="loader-charg">Chargement</span>
  </div>
) : paginatedProducts.length > 0 ? (
  <div className="container">
    <div className="row" style={{marginLeft:"30px"}}>
      {paginatedProducts.map((pro) => (
        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={pro.id}>
          <Link className="product-link" to={`/articles/${pro.RefArticle[0]}`}>
          <div className="u-card">
            <div className="u-off">
              <span className='off'>
                25% off
              </span>
            </div>
            <img src={pro.urlImage} alt={pro.Designation} className="u-card-img" />
            <div className="u-card-icons">
      <i className="bi bi-suit-heart u-heart"></i>
      <i className="bi bi-cart u-card-shopping"></i>
      </div>
            <div className="u-km">
              <p>À 10 Km de chez vous</p>
            </div>
            <div className="u-star">
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
              <i className="bi bi-star-fill u-i-star"></i>
            </div>
            <div className="u-card-title">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</div>
            <p className="u-card-ref-ar">Ref : {pro.RefArticle[0]}</p>
            <div className="u-card-price">{pro.PrixVenteArticleHT} MAD</div>
          </div>
          </Link>
        </div>
      )
      )
      }
      
    </div>
    
    {/* Pagination */}
    <div className="pagination d-flex justify-content-center">
      <button
        style={{ borderRadius: "100%" }}
        className="page-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
      <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
      <button
        style={{ borderRadius: "100%" }}
        className="page-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage * recordsPerPage >= filteredProducts.length}
      >
        <i className="bi bi-arrow-right"></i>
      </button>
    </div>
  </div>
) : (
  <div className="no-produit">
          <i className="bi bi-emoji-neutral"></i>
          <br />
          <p>
            Malheureusement, on n‘a pas ce produit pour l’instant.
          </p>
          <br />
        </div>
)}

  </div>
      </div>
  <br /><br />
</div>
</>


      {/* {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '550px', color:"#FFC400" }}>
            <div className="spinner-border custom-spinner" role="status">
            </div>
            <span className="loader-charg">Chargement</span>
          </div>
        ) : (
      <div className="categ">
        <Link to={`/magasins/${id}/panier`}>
          <div className="panier">
            <i className="bi bi-cart3"></i>
            {localStorageData?.length > 0 ? <span className="num">{localStorageData?.length}</span> : ""}
            </div>
        </Link>
        <div className="container">
          <h5 className="mt-5">
            <b>Bienvenue ! </b> vous pouvez gérer votre stock en toute simplicité
          </h5>
        </div>
        <div className="container mt-5">
          <div className="row height">
            <div className="col-md-6">
              <div className="form">
                <i className="fa fa-search"/>
                <input onChange={handleSearch}type="text" className="form-control form-input" placeholder="Recherch un produit , ref .."/>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" id="ProductSlide">
          <h5 className="container">Equipements</h5>
          <Link to={`/magasins/${id}/articles`}>
            <a className="right-align" href="#">
              Voir Plus <i className="bi bi-arrow-right-short" />
            </a>
          </Link>
          <Swiper
      freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
    >
            {paginatedProducts && paginatedProducts.length > 0 ? (
              paginatedProducts
                .filter((pro) => {
                  const searchTerm = search.trim().toLowerCase();
                  const designation = pro.Designation.toLowerCase();
                  const designationAr = pro.DesignationAr?.toLowerCase();
                  const refArticle = pro.RefArticle.toString().toLowerCase();
                  const sub = pro.LibelleSubstitut?.toString().toLowerCase();

                  const searchTerms = searchTerm.split(" ");

                  return searchTerms.every(
                    (term) =>
                      designation.includes(term) ||
                      designationAr?.includes(term) ||
                      refArticle.startsWith(term) ||
                      (sub && sub.startsWith(term))
                  );
                })
                .map((pro) => (
                  <SwiperSlide className="upBox" key={pro.IdArticle}>
                    <Link
                      className="hh"
                      to={`/magasins/${id}/articles/${pro.RefArticle[0]}`}
                    >
                      <div className="box">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <div className="slidImage">
                          <img src={pro.urlImage} alt="" />
                          <div className="overlay"></div>
                        </div>
                        <div className="detailBoxMagasin">
                          <div className="type">
                            <p className="paran">{pro.Designation}{pro.DesignationAr ?` | ${pro.DesignationAr}` :""}</p>
                            <i className="bi bi-cart-plus"></i>
                          </div>
                          <div className="price">
                            <p>
                              {pro.PrixVenteArticleHT} MAD<span>/P.U</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))
            ) : (
              <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i>
                <br />
                <p>
                  Malheureusement, on n‘a pas ce produit pour l’instant.
                </p>
                <br />
                <Link
                  to={"https://api.whatsapp.com/send?phone=212661718081"}
                  target="_blank"
                  className="botton-remplir"
                >
                  <button>
                    <i className="bi bi-whatsapp"></i>Contactez-nous
                  </button>
                </Link>
              </div>
            )}
          </Swiper>
        </div>

        <div className="row container mx-auto">
      {paginatedProducts && paginatedProducts.length > 0 ? (
        paginatedProducts.map((pro) => (
          <div className="col-lg-4 col-md-3 col-12" key={pro.IdArticle}>
            <Link className="upBox" to={`/magasins/${id}/articles/${pro.RefArticle}/${pro.PrixVenteArticleHT}`}>
              <div className="box">
                <div className="slidImage">
                  {pro.Remise && pro.Remise > 0 ? (
                    <span className="reduction">{pro.Remise}% de Remise</span>
                  ) : ""}
                  <img src={pro.urlImage} alt={pro.urlImage} />
                  <div className="overlay"></div>
                </div>
                <div className="detailBoxMagasin">
                  <div className="type">
                    <p className="paran">{pro.Designation}{pro.DesignationAr ? ` | ${pro.DesignationAr}` : ""}</p>
                    <i className="bi bi-cart-plus"></i>
                  </div>
                  <div className="price">
                    <p>{pro.PrixVenteArticleHT} MAD<span>/P.U</span></p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        ""
      )}

      <div style={{marginLeft:"15%"}}  className="pagination mb-5">
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                précedent
              </button>
              <span className="page-info">Page {currentPage} of {Math.ceil(filteredProducts.length / recordsPerPage)}</span>
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage * recordsPerPage >= filteredProducts.length}
              >
                Suivant
              </button>
            </div>
    </div>
      </div>
        )} */}
    </>
  );
}
